import React, { FC, useContext, useEffect } from "react"
import * as css from "./Modal.module.scss"
import { GlobalContext } from "~/GlobalProvider"

export enum ModalSize {
  Small = "small",
  Medium = "medium",
  Cover = "cover",
}

const Modal: FC = () => {
  const {
    modalOpen,
    modalContents,
    modalSize,
    modalClassName,
    closeModal,
  } = useContext(GlobalContext)

  useEffect(() => {

    if (modalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }

  }, [modalOpen])

  return (
    <div
      className={`modal ${css.wrapper} ${css[modalSize]} ${
        modalOpen ? css.open : ''
      }`}
    >
      <div
        className="backdrop backdrop--darker"
        onClick={() => {
          closeModal()
        }}
      />
      <div className={`${css.innerWrapper} ${modalClassName || ''}`}>
        <div className={css.closeButton}>
          <button
            onClick={() => {
              closeModal()
            }}
          >
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5689 22L7.03442 34.5345L9.46542 36.9655L21.9999 24.431L34.5344 36.9655L36.9654 34.5345L24.4309 22L36.9654 9.4655L34.5344 7.0345L21.9999 19.569L9.46542 7.0345L7.03442 9.4655L19.5689 22Z"
                fill="#537495"
              />
            </svg>
          </button>
        </div>
        {modalContents}
      </div>
    </div>
  )
}

export default Modal
