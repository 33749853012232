import React, { FC, useContext } from "react"
import { GlobalContext } from "~/GlobalProvider"
import Link from "~/components/atoms/Link/Link"
import * as css from "./SideMenu.module.scss"

const SideMenu: FC = () => {
  const { companyInfo, navigation, menuOpen, setMenuOpen } =
    useContext(GlobalContext)

  return (
    <div className={`${css.wrapper} ${css[menuOpen ? "open" : ""]}`}>
      <div
        className="backdrop"
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      />
      <div className={css.inner}>
        <div className={css.top}>
          <span>{navigation?.name}</span>
          <button
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}
          >
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5689 22L7.03442 34.5345L9.46542 36.9655L21.9999 24.431L34.5344 36.9655L36.9654 34.5345L24.4309 22L36.9654 9.4655L34.5344 7.0345L21.9999 19.569L9.46542 7.0345L7.03442 9.4655L19.5689 22Z"
                fill="#537495"
              />
            </svg>
          </button>
        </div>
        <div className={css.menuItems}>
          {navigation?.pages?.map(({ name, slug }, index) => (
            <Link to={`/${slug}`} key={index}>
              {name}
            </Link>
          ))}
        </div>
        <div className={css.bottom}>
          {companyInfo.bookingSystem && (
            <Link button to={companyInfo.bookingSystem}>
              Check availability
            </Link>
          )}
          <Link
            className={css.email}
            to={`tel:${companyInfo.email.replace(/ /g, "")}`}
          >
            {companyInfo.email}
          </Link>
          <Link
            className={css.telephoneNumber}
            to={`tel:${companyInfo.telephoneNumber.replace(/ /g, "")}`}
          >
            {companyInfo.telephoneNumber}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SideMenu
