import { GatsbyImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import { StructuredText } from 'react-datocms'
import * as css from './ImageAndText.module.scss'

const ImageAndText: FC<any> = ({image, text, bigImage}) => (
  <div className={css.wrapper}>
    <div className={css.inner}>
      <GatsbyImage image={image.gatsbyImageData}
        alt={image.name}
      />
      <StructuredText data={text} />
    </div>
  </div>
)

export default ImageAndText