import React, { FC } from 'react'
import * as css from './Space.module.scss'

export enum SpaceSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const Space: FC<{ size: SpaceSize }> = ({ size }) => (
  <div className={`${css.wrapper} ${css[size]}`} />
)

export default Space