import { GatsbyImage } from 'gatsby-plugin-image'
import React, { FC, useContext } from 'react'
import { GlobalContext } from "~/GlobalProvider"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import * as css from './Grid.module.scss'
import Slideshow from '../Slideshow/Slideshow'
import Space, { SpaceSize } from '../Space/Space'
import { StructuredText } from 'react-datocms/structured-text'

const Grid: FC<any> = ({ items }) => {
  const { openModal } = useContext(GlobalContext)

  const openItem = (item) => {
    console.log(item.description)
    openModal(
      <>
        <div className={css.modalHeader}>
          <h3 className={css.modalHeading}>{item.title}</h3>
          {item.description?.value && (
            <div className={css.modalDescription}>
              <StructuredText data={item.description} />
            </div>
          )}
        </div>
        <Space size={SpaceSize.Small} />
        <div className={css.modalSlideshow}>
          <Slideshow contain images={[item.image, ...item.otherImages]} />
        </div>
        <div className={css.modalScroll}>
          {[item.image, ...item.otherImages].map((image, index) => (
            <div key={index} className={css.modalThumbnail}>
              <GatsbyImage image={image.gatsbyImageData} alt={item.title} />
            </div>
          ))}
        </div>
      </>, {
        className: css.modal,
      }
    )
  }

  return (
    <div className={css.wrapper}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 690: 3 }}>
        <Masonry className={css.grid}>
          {items.map((item, index) => (
            <div key={index} className={css.gridItem} onClick={() => { openItem(item) }}>
              <GatsbyImage image={item.image.gatsbyImageData} alt={item.title} />
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  )
}

export default Grid