import React, { FC } from "react"

type PDFViewerProps = {
  file: any
}

const PDFViewer: FC<PDFViewerProps> = ({ file }) => (
  <embed style={{ width: "100vw", height: "100vh" }} src={`https://drive.google.com/viewerng/
viewer?embedded=true&url=${file.url}`} type="application/pdf" />
)

export default PDFViewer
