import React, { FC, useState, useEffect, useCallback } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as css from "./Slideshow.module.scss"

type ImageSlideshowProps = {
  images: Array<any>
  absolute?: boolean
  background?: boolean
  contain?: boolean
  paused?: boolean
}

const Slideshow: FC<ImageSlideshowProps> = ({ images, contain, absolute, background, paused }) => {
  if (images.length < 2) {
    return (
      <div className={`${css.wrapper} ${absolute && css.absolute} ${background && css.background} ${contain && css.contain}`}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`${css.imageWrapper} ${css.current}`}
        >
          <GatsbyImage image={image.gatsbyImageData}
            className={css.image}
            alt={`${index}`}
          />
        </div>
      ))}
    </div>
    )
  }

  const slideshowIntervalSeconds = 7.5

  const [visibleImages, setVisibleImages] = useState<any>({
    previous: images.length - 1,
    current: 0,
    next: 1,
  })

  const paginateLeft = useCallback(() => {
    setVisibleImages((i) => {
      return {
        previous: i.previous <= 0 ? images.length - 1 : i.previous - 1,
        current: i.previous,
        next: i.current,
      }
    })
  }, [])

  const paginateRight = useCallback(() => {
    setVisibleImages((i) => {
      return {
        previous: i.current,
        current: i.next,
        next: i.next >= images.length - 1 ? 0 : i.next + 1,
      }
    })
  }, [])

  const handleImageClick = useCallback((imageIndex) => {
    if (background || !paused) {
      return
    }

    if (imageIndex === visibleImages.current) {
      return
    }

    if (imageIndex === visibleImages.previous) {
      paginateLeft()
      return
    }

    if (imageIndex === visibleImages.next) {
      paginateRight()
      return
    }
  }, [visibleImages])

  const initInterval = () => {
    return setInterval(() => {
      paginateRight()
    }, slideshowIntervalSeconds * 1000)
  }

  let interval
  const visibilityChangeEventListener = () => {
    if (document.visibilityState === "hidden" && !!interval) {
      clearInterval(interval)
      return
    }

    if (document.visibilityState === "visible") {
      interval = initInterval()
    }
  }

  useEffect(() => {
    if (paused) {
      // Don't start the interval if it's paused and clear the interval if it's already running
      clearInterval(interval)
      return
    }

    if (document.visibilityState === "visible") {
      interval = initInterval()
    }

    document.addEventListener("visibilitychange", visibilityChangeEventListener)

    return () => {
      clearInterval(interval)

      document.removeEventListener(
        "visibilitychange",
        visibilityChangeEventListener
      )
    }
  }, [paused])

  return (
    <div className={`${css.wrapper} ${absolute && css.absolute} ${background && css.background} ${contain && css.contain}`}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`${css.imageWrapper} ${
            visibleImages.previous === index ? css.previous : ""
          } ${
            visibleImages.current === index ? css.current : ""
          }`}
          onClick={() => { handleImageClick(index) }}
        >
          <GatsbyImage image={image.gatsbyImageData}
            className={css.image}
            alt={`${index}`}
          />
        </div>
      ))}
    </div>
  )
}

export default Slideshow
