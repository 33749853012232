import React, { useCallback, useState } from "react"
import * as css from "./BigNavigation.module.scss"
import { navItems } from "../../../data"
import GatsbyLink from "gatsby-link"
import ArrowBottomLeft from "../../../vectors/ArrowBottomLeft"
import ArrowTopLeft from "../../../vectors/ArrowTopLeft"


const BigNavigation = () => {
  const [visibleChildren, setVisibleChildren] = useState<Array<any>>([])

  const clickItem = useCallback((index: number, children?: Array<any>) => {
    setVisibleChildren(children || [])
  }, [])

  return (
    <>
      <div className={`${css.wrapper} ${visibleChildren?.length && css.hideInMobile}`}>
        {navItems.map(({ name, Vector, to, children }, index) => {
          const Tag = to ? GatsbyLink : ({ ...props }) => <div {...props} />

          return (
            <Tag
              className={css.item}
              key={index}
              onClick={() => {
                clickItem(index, children || [])
              }}
              to={to || ""}
              title={name}
            >
              <Vector />
            </Tag>
          )
        })}
      </div>
      <div className={`${css.children} ${visibleChildren?.length && css.visible}`}>
        {visibleChildren.map(({ name, Vector, to }, index) => (
          <GatsbyLink className={css.childItem} key={index} to={to} title={name}>
            <Vector />
          </GatsbyLink>
        ))}
      </div>
      <div className={`${css.childrenArrows} ${visibleChildren?.length && css.visible}`}>
        <ArrowTopLeft />
        <ArrowBottomLeft />
      </div>
    </>
  )
}

export default BigNavigation
