import React from "react"
import About from "./vectors/About"
import Work from "./vectors/Work"
import ProductionHouse from "./vectors/ProductionHouse"
import Wallpapers from "./vectors/Wallpapers"
import Contact from "./vectors/Contact"

export const navItems = [
  {
    name: "About",
    to: "/about/",
    Vector: About,
  },
  {
    name: "Work",
    Vector: Work,
    children: [
      {
        name: "Production house",
        to: "/production-house/",
        Vector: ProductionHouse,
      },
      {
        name: "Wallpapers",
        to: "/wallpapers/",
        Vector: Wallpapers,
      },
    ],
  },
  {
    name: "Contact",
    to: "/contact/",
    Vector: Contact,
  },
]
