import React, { FC, useEffect, useState } from "react"
import { StructuredText } from "react-datocms"
import * as css from "./Map.module.scss"

type MapProps = {
  heading: string
  subheading: any
}

const Map: FC<MapProps> = ({ heading, subheading }) => {
  const [iframeUrl, setIframeUrl] = useState("")

  // defer iframe loading
  useEffect(() => {
    setIframeUrl(
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2545.064100939867!2d-4.3901577840080845!3d50.3653592013731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c9bc915bfc7c9%3A0x98f7994623ebe661!2sMount%20Brioni!5e0!3m2!1sen!2suk!4v1635301416028!5m2!1sen!2suk"
    )
  }, [])

  return (
    <div className={css.wrapper}>
      <div className={css.inner}>
        <div className={css.details}>
          <h2>{heading}</h2>
          <StructuredText data={subheading} />
        </div>
        <div className={css.iframeWrapper}>
          <iframe
            src={iframeUrl}
            frameBorder="0"
            aria-hidden="false"
            tabIndex={0}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  )
}

export default Map
