import React, { FC, useContext } from 'react'
import { GlobalContext } from "~/GlobalProvider"
import * as css from './CompanyInfo.module.scss'

const CompanyInfo: FC<any> = ({ size }) => {
  const { companyInfo } = useContext(GlobalContext);

  return (
    <div className={`${css.wrapper} ${css[size]}`}>
      <img className={css.topLogo} src={companyInfo.logoMaximus.url} />
      <div className={css.inner}>
      <div className={css.left}>
        <img className={css.logo} src={companyInfo.logoMaximus.url} />
        Unit D<br />
        195 Eade Road<br />
        London<br />
        N4 1FF
      </div>
      <div className={css.right}>
        <a href="mailto:enquiries@oddstudio.london">enquiries@oddstudio.london</a><br /><br />
        Francesco: <a href="tel:07901341185">07901 341185</a><br />
        Sam: <a href="tel:07815312042">07815 312042</a>
        </div>
        </div>
    </div>
  )
}

export default CompanyInfo