import GatsbyLink from "gatsby-link"
import React, { FC, useEffect, useState } from "react"
import * as css from "./Link.module.scss"

type LinkProps = {
  button?: boolean
  to: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
}

const Link: FC<LinkProps> = ({ button, to, onClick, className, children }) => {
  const [linkClassName, setLinkClassName] = useState(className)

  useEffect(() => {
    setLinkClassName(`${button ? css.button : ""} ${className}`)
  }, [])

  if (!to && onClick) {
    return (
      <button onClick={onClick} className={linkClassName}>
        {children}
      </button>
    )
  }

  if (to[0] === "/") {
    return (
      <GatsbyLink to={to} className={linkClassName}>
        {children}
      </GatsbyLink>
    )
  }

  if (!to.split(":")[0]) {
    return null
  }

  return (
    <a
      className={linkClassName}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default Link
