import { graphql, useStaticQuery } from "gatsby"
import React, { Context, Dispatch, SetStateAction, useState } from "react"
import { ModalSize } from "./components/atoms/Modal/Modal"

export const GlobalContext: Context<{
  navigation?: {
    name: string
    pages?: Array<{
      name: string
      slug: string
    }>
  }
  companyInfo?: any
  menuOpen?: boolean
  setMenuOpen?: Dispatch<SetStateAction<boolean>>
  modalOpen?: boolean
  modalContents?: JSX.Element
  modalClassName?: string
  modalSize?: ModalSize
  openModal?: (contents: JSX.Element, options: { size: ModalSize, className?: string }) => void
  closeModal?: () => void
}> = React.createContext({})

export const GlobalProvider = ({ children }: { children: any }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContents, setModalContents] = useState<JSX.Element>()
  const [modalSize, setModalSize] = useState<ModalSize>()
  const [modalClassName, setModalClassName] = useState<string>()

  const openModal = (contents: JSX.Element, options: { size: ModalSize, className?: string }) => {
    setModalContents(contents)
    setModalSize(options?.size || ModalSize.Cover)
    setModalClassName(options?.className || '')
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setModalContents(undefined)
    setModalSize(undefined)
    setModalClassName(undefined)
  }

  const {
    datoCmsCompanyInformation: companyInfo,
    datoCmsNavigation: navigation,
  }: Record<string, any> = useStaticQuery(graphql`
    query MyGlobalQuery {
      datoCmsNavigation {
        name
        pages {
          name
          slug
        }
      }
      datoCmsCompanyInformation {
        telephoneNumber
        logoMinimal {
          url
        }
        logoMaximus {
          url
        }
        companyName
        email
        address
        fbUrl
      }
    }
  `)

  return (
    <GlobalContext.Provider
      value={{
        navigation,
        companyInfo,
        menuOpen,
        setMenuOpen,
        modalOpen,
        modalContents,
        modalClassName,
        modalSize,
        openModal,
        closeModal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
