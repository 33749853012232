import React, { FC } from "react"
import Quotes from "./components/Quotes/Quotes"
import Map from "./components/Map/Map"
import NewsletterSignup from "./components/NewsletterSignup/NewsletterSignup"
import Text from "./components/Text/Text"
import Slideshow from "./components/Slideshow/Slideshow"
import BigNavSlideshow from "./components/BigNavSlideshow/BigNavSlideshow"
import Grid from "./components/Grid/Grid"
import Space from "./components/Space/Space"
import ImageAndText from "./components/ImageAndText/ImageAndText"
import Rule from "./components/Rule/Rule"
import CompanyInfo from "./components/CompanyInfo/CompanyInfo"
import PDFViewer from "./components/PDFViewer/PDFViewer"

const Block: FC<any> = props => {
  const BlockType = {
    DatoCmsQuoteBlock: Quotes,
    DatoCmsMap: Map,
    DatoCmsNewsletterSignup: NewsletterSignup,
    DatoCmsText: Text,
    DatoCmsImageAndText: ImageAndText,
    DatoCmsSlideshow: Slideshow,
    DatoCmsBigNavSlideshow: BigNavSlideshow,
    DatoCmsGrid: Grid,
    DatoCmsSpace: Space,
    DatoCmsRule: Rule,
    DatoCmsCompanyInfo: CompanyInfo,
    DatoCmsPdfViewer: PDFViewer,
  }

  const { internal, children } = props

  if (!internal?.type || !BlockType[internal?.type]) {
    return null
  }

  const RenderBlock: FC = ({ children }) => {
    const Block = BlockType[internal?.type]

    return <Block {...props}>{children}</Block>
  }

  return <RenderBlock>{children}</RenderBlock>
}

export default Block
