import React, { FC } from "react"
import SEO from "~/utils/SEO"
import Footer from "~/components/molecules/Footer/Footer"
import Block from "~/components/atoms/Block"
import Modal from "~/components/atoms/Modal/Modal"
import SideMenu from "../SideMenu/SideMenu"
import Navigation from "../Navigation/Navigation"

import * as css from './Page.module.scss'

type PageProps = {
  name: string
  heading: string
  slug: string
  blocks: Array<any>
  hideTopNavigation?: boolean
  description?: any
  image: any
  className?: string
  seo: any
  subheading?: string
  content: Array<any>
}

const Page: FC<PageProps> = ({
  heading,
  subheading,
  slug,
  content,
  hideTopNavigation,
  image,
  className,
  seo,
}) => {
  const page = slug || 'homepage'

  return (
    <div className={css[slug || 'homepage']}>
      <main>
        <div className={`page ${className}`}>
          <SEO
            {...{
              title: seo?.title || heading,
              description: seo?.description || subheading || "",
              slug,
              image,
            }}
          />
          {hideTopNavigation !== true && <Navigation invert={page === 'homepage'} />}
          <div className="page-content">
            {(content || []).map((contentItem, index) => <Block key={index} {...contentItem} />)}
          </div>
        </div>
      </main>
      {/* <Footer /> */}
      <SideMenu />
      <Modal />
    </div>
  )
}

export default Page
