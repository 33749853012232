import React, { FC, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import PreloadFont1 from "~/assets/fonts/Karla-Bold.woff2"
import PreloadFont2 from "~/assets/fonts/Karla-BoldItalic.woff2"
import PreloadFont3 from "~/assets/fonts/Karla-Regular.woff2"
import PreloadFont4 from "~/assets/fonts/LibreBaskerville-Regular.woff2"

type SEOProps = {
  title: string
  slug: string
  description: string
  image?: Record<string, any>
}

const SEO: FC<SEOProps> = ({ title, slug, description, image }) => {
  const siteName = 'Odd Studio'
  const siteUrl = `https://oddstudio.london`

  const stripHtml = (input: string) => {
    return input.replace(/<\/?[^>]+(>|$)/g, "")
  }

  const [desc, setDesc] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  useEffect(() => {
    if (description) {
      setDesc(stripHtml(description))
    }
  }, [description])

  useEffect(() => {
    if (image?.file?.url) {
      setImageUrl(image.file.url)
    }
  }, [image])

  return (
    <Helmet>
      <html lang="en" />
      <title>{title || siteName}</title>
      <meta name="title" content={title} />
      {desc && <meta name="description" content={desc} />}
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta name="viewport" content="width=device-width" />
      <link
        rel="preload"
        as="font"
        href={PreloadFont1}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={PreloadFont2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={PreloadFont3}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={PreloadFont4}
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <link rel="icon" href="/favicon.ico" />
      <link rel="icon" type="image/png" href="/favicon.png" />
      <link rel="icon" href="/favicon.svg" />
      <link rel="mask-icon" href="mask-icon.svg" color="#efece0" />
      <link rel="canonical" href={siteUrl} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {desc && <meta property="og:desc" content={desc} />}
      <meta property="og:url" content={`${siteUrl}${slug ? `/${slug}` : ""}`} />
      <meta property="og:site_name" content={siteName} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {imageUrl && <meta property="og:image:secure_url" content={imageUrl} />}
      {imageUrl && <meta property="og:image:width" content="300" />}
      {imageUrl && <meta property="og:image:height" content="300" />}
      {imageUrl && <meta name="twitter:card" content={imageUrl} />}
      {desc && <meta name="twitter:desc" content={desc} />}
      <meta name="twitter:title" content={title} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  )
}

export default SEO
