// extracted by mini-css-extract-plugin
export var background = "Navigation-module--background--c0a8b";
export var bounce = "Navigation-module--bounce--e659a";
export var burger = "Navigation-module--burger--750a4";
export var invert = "Navigation-module--invert--1c376";
export var large = "Navigation-module--large--69d83";
export var light = "Navigation-module--light--21fd0";
export var logo = "Navigation-module--logo--4b863";
export var navigation = "Navigation-module--navigation--0f229";
export var pageIntro = "Navigation-module--pageIntro--f65d5";
export var rightSide = "Navigation-module--rightSide--c94b6";
export var scrollArrow = "Navigation-module--scrollArrow--f7c72";
export var social = "Navigation-module--social--edfd8";
export var telephoneNumber = "Navigation-module--telephoneNumber--ade0a";
export var text = "Navigation-module--text--c4cdf";
export var wrapper = "Navigation-module--wrapper--7dd4f";