import React, { FC } from 'react'

import * as css from "./BigNavSlideshow.module.scss"
import Slideshow from '../Slideshow/Slideshow'
import BigNavigation from '../../../../molecules/BigNavigation/BigNavigation'

type ImageSlideshowProps = {
  images: Array<any>
  useVectors?: boolean
}

const BigNavSlideshow: FC<ImageSlideshowProps> = ({ images }) => (
  <div className={css.wrapper}>
    <Slideshow images={images} absolute background />
    <BigNavigation />
  </div>
)

export default BigNavSlideshow
